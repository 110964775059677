export const isSafari = () =>
  navigator.vendor &&
  navigator.vendor.indexOf("Apple") > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf("CriOS") === -1 &&
  navigator.userAgent.indexOf("FxiOS") === -1;
export const isFirefox = () =>
  navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
export const isChrome = () =>
  navigator.userAgent.toLowerCase().indexOf("chrome") > -1;
export const targetStatus = () =>
  window.innerWidth >= 1200 && !isFirefox() && !isSafari();
