import "./src/assets/scss/global.scss";
import React from "react";
import locationController from "./src/controllers/common/locationController";
import extraLocalStyleController from "./src/controllers/common/extraLocalStyleController";
import updaterRedirectController from "./src/controllers/services/updaterRedirectController";
import MediaAuthProvider from "./src/store/providers/MediaAuthProvider";

export const onClientEntry = () => {
  locationController();
  process.env.GATSBY_UPDATER_ENV && updaterRedirectController();
};

export const onInitialClientRender = () => {
  extraLocalStyleController();
};

export const wrapRootElement = ({ element }) => {
  return (
    <MediaAuthProvider>{element}</MediaAuthProvider>
  );
};