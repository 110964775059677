import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { log } from "../helpers";

const BREAKPOINTS_INTERVALS = [
  { name: "isNotInitMobile", query: "(max-width: 767px)" },
  { name: "isMobile", query: "(max-width: 767px)" },
  {
    name: "isTabletOrSmallDesktop",
    query: "(min-width: 768px) and (max-width: 1199px)",
  },
  { name: "isTablet", query: "(min-width: 768px) and (max-width: 991px)" },
  { name: "isTabletOrLess", query: "(max-width: 991px)" },
  {
    name: "isSmallDesktop",
    query: "(min-width: 992px) and (max-width: 1199px)",
  },
  { name: "isSmallDesktopOrLess", query: "(max-width: 1199px)" },
  { name: "isDesktop", query: "(min-width: 1200px)" },
];

const useMediaAuth = () => {
  const [auth, setAuth] = useState({ isAuth: false, h: "" });
  const [breakpoints, setBreakpoints] = useState({
    isNotInitMobile: false,
    isMobile: true,
    isTabletOrSmallDesktop: false,
    isTablet: false,
    isTabletOrLess: false,
    isSmallDesktop: false,
    isSmallDesktopOrLess: false,
    isDesktop: false,
  });

  const updateBreakpoints = useCallback((key, value) => {
    setBreakpoints((prevState) =>
      prevState[key] !== value ? { ...prevState, [key]: value } : prevState
    );
  }, []);

  const updateAuth = useCallback((key, value) => {
    setAuth((prevState) =>
      prevState[key] !== value ? { ...prevState, [key]: value } : prevState
    );
  }, []);

  const iterateAllPoints = useCallback(() => {
    if (typeof window !== "undefined") {
      BREAKPOINTS_INTERVALS.forEach(({ name, query }) => {
        updateBreakpoints(name, window.matchMedia(query).matches);
      });
    }
  }, [updateBreakpoints]);

  const checkAuth = useCallback(() => {
    if (typeof window !== "undefined") {
      const emailCollect = localStorage.getItem("emailCollect");
      const collectData = localStorage.getItem("collectData");
      const h = document.cookie.match(/(^|; )h=([^;]*)/)?.[2] || null;
      const isAuth = (!!emailCollect && !!collectData) || !!h;
      log(`isAuth: ${isAuth}, h: ${h}`, "AUTH");
      updateAuth("isAuth", isAuth);
      updateAuth("h", h);
    }
  }, [updateAuth]);

  useLayoutEffect(() => {
    checkAuth();
    iterateAllPoints();
  }, []);

  useEffect(() => {
    window.addEventListener("resize", iterateAllPoints);
    return () => {
      window.removeEventListener("resize", iterateAllPoints);
    };
  }, [iterateAllPoints]);

  return {
    media: breakpoints,
    auth: { isAuth: auth.isAuth, h: auth.h, checkAuth },
  };
};

export default useMediaAuth;
