import React from "react";
import { MediaAuthContext } from "../context/media-auth-context";
import useMediaAuth from "../../hooks/useMediaAuth";

const MediaAuthProvider = ({ children }) => {
  const mediaAuth = useMediaAuth();
  return (
    <MediaAuthContext.Provider value={mediaAuth}>{children}</MediaAuthContext.Provider>
  );
};

export default MediaAuthProvider;
