import { createContext } from "react";

export const MediaAuthContext = createContext({
  // media: {
  //   isNotInitMobile: false,
  //   isMobile: true,
  //   isTablet: false,
  //   isTabletOrLess: false,
  //   isSmallDesktop: false,
  //   isDesktop: false,
  // },
  // auth: { isAuth: false, checkAuth }
});
