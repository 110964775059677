import { updateObjectKey } from "../helpers/data";

export const trackGa = (action, event) => {
  updateObjectKey(event, "category", "event_category");
  if (action !== "goto_link_click") {
    updateObjectKey(event, "label", "event_label");
  }
  if (typeof window !== "undefined") {
    try {
      window.gtag("event", action, { ...event });
    } catch (e) {
      console.warn(e);
    }
  }
};
