module.exports = function () {
  const host = window.location.hostname;
  const words = host.length;
  const params = new URL(window.location.href).searchParams;
  const isCustom = params.get("bc");
  const destination = "aHR0cHM6Ly9hbXpzY291dC5uZXQ=";

  if (
    (host !== process.env.HOST.split("//")[1] ||
      words !== parseInt(process.env.HOST_LENGTH) ||
      !!isCustom) &&
    host !== "localhost"
  ) {
    if (typeof document !== "undefined") {
      if (process.env.FISHING_REDIRECT === "true") {
        window.setInterval(() => {
          window.location.replace(atob(destination) + window.location.pathname);
        }, 1000);
      }
      document.querySelector("html").remove();
      localStorage.clear();
    }
  }
};
