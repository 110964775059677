const _gatsby = require("gatsby");
const { LinkElement } = require("../../helpers/index");
const withPrefix = _gatsby.withAssetPrefix || _gatsby.withPrefix;

module.exports = function () {
  if (window.location.hostname === "localhost") {
    const iframeLinkElement = new LinkElement(
      withPrefix("/external/css/iframe.min.css"),
      "stylesheet",
      "text/css"
    );
    if (typeof window !== "undefined") {
      const head = document.querySelector("head");
      const pathname = window.location.pathname;
      if (pathname.includes("iframe/webinars")) {
        head.appendChild(iframeLinkElement.getElement());
      }
    }
  }
};
